<script>
import DatePicker from "vue2-datepicker";
import Repositories from "@/repositories";
const WorkOrderTypesRepository = Repositories.get("workordertypes");
const InstallersRepository = Repositories.get("v1.1/installers");
const ShippersRepository = Repositories.get("shippers");
import CalendarApiService from "@/services/calendarService";
const ProjectEnvironmentsRepository = Repositories.get("projectenvironments");
const ContractorsRepository = Repositories.get("contractors");
const ProjectManagersRepository = Repositories.get("projectmanagers");
import AutoComplete from "@/components/autocomplete";
export default {
  components: {
    DatePicker,
    AutoComplete,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      workOrderTypes: [],
      installers: [],
      projectEnvironmentList: [],
      contractorList: [],
      projectManagerList: [],
      shippers: [],
      workOrderGroups: [],
      isLoadingGroup: false,
      currentFilters: {
        sort: {},
      },
    };
  },
  created() {
    this.load();
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    load() {
      this.isLoading = true;

      var workOrderTypePromise = WorkOrderTypesRepository.get().then(
        (response) => {
          this.workOrderTypes = response.data.items;
        }
      );

      var installersPromise = InstallersRepository.get().then((response) => {
        this.installers = response.data.items;
      });

      var shippersPromise = ShippersRepository.get().then((response) => {
        this.shippers = response.data.items;
      });

      var projectEnvironmentsPromise = ProjectEnvironmentsRepository.get().then(
        (response) => {
          this.projectEnvironmentList = response.data.map((x) => x.name);
        }
      );

      var contractorsPromise = ContractorsRepository.get().then((response) => {
        this.contractorList = response.data.map((x) => x.name);
      });
      var projectManagerPromise = ProjectManagersRepository.get().then(
        (response) => {
          this.projectManagerList = response.data.map((x) => x.name);
        }
      );

      Promise.all([
        workOrderTypePromise,
        installersPromise,
        shippersPromise,
        projectEnvironmentsPromise,
        contractorsPromise,
        projectManagerPromise,
      ]).finally(() => {
        this.isLoading = false;
        this.currentFilters = this.getCacheFilters();
        this.$emit(
          "apply-filters",
          JSON.parse(JSON.stringify(this.currentFilters))
        );
      });
    },
    async searchWorkOrderGroups(queryString) {
      if (queryString == "" || queryString == null) return;

      //debounce input event
      if (window.LIT3 != null) {
        clearTimeout(window.LIT2);
      }

      window.LIT3 = setTimeout(async () => {
        //searchWorkOrderGroup
        this.isLoadingGroup = true;
        var response = await CalendarApiService.getWorkOrderGroups(queryString);
        this.isLoadingGroup = false;
        var groups = response.data.data;
        this.workOrderGroups = [];

        for (var group of groups) {
          this.workOrderGroups.push(group);
        }
      }, 500);
    },
    clearFilters() {
      this.currentFilters = this.getDefaultFilters();
      this.applyFilters();
    },
    applyFilters() {
      this.saveFilters();
      this.$emit(
        "apply-filters",
        JSON.parse(JSON.stringify(this.currentFilters))
      );
    },
    getCacheFilters() {
      var defaultFilters = this.getDefaultFilters();
      if (!localStorage.getItem("workOrders.filters")) {
        return defaultFilters;
      }
      var filters = JSON.parse(localStorage.getItem("workOrders.filters"));
      if (!filters.sort) {
        filters.sort = { by: "creationDate", desc: true };
      }
      if (filters.productionDateRange != null) {
        filters.productionDateRange[0] = new Date(
          filters.productionDateRange[0]
        );
        filters.productionDateRange[1] = new Date(
          filters.productionDateRange[1]
        );
      }
      if (filters.deliveryDateRange != null) {
        filters.deliveryDateRange[0] = new Date(filters.deliveryDateRange[0]);
        filters.deliveryDateRange[1] = new Date(filters.deliveryDateRange[1]);
      }
      if (filters.installationDateRange != null) {
        filters.installationDateRange[0] = new Date(
          filters.deliveryDateRange[0]
        );
        filters.installationDateRange[1] = new Date(
          filters.installationDateRange[1]
        );
      }
      return filters;
    },
    saveFilters() {
      localStorage.setItem(
        "workOrders.filters",
        JSON.stringify(this.currentFilters)
      );
    },
    getDefaultFilters() {
      return {
        search: null,
        workOrderType: null,
        workOrderGroups: [],
        roomName: null,
        clientName: null,
        contractor: null,
        projectManager: null,
        productionDateRange: null,
        deliveryDateRange: null,
        installationDateRange: null,
        shippers: [],
        installers: [],
        showCompleted: false,
        sort: { by: "creationDate", desc: true },
        ready: true,
      };
    },
  },
  computed: {
    orderByOptions() {
      return [
        { value: "creationDate", text: this.$t("general.creationDate") },
        { value: "name", text: this.$t("general.workOrder") },
        { value: "clientName", text: this.$t("general.client") },
        { value: "percentCompleted", text: this.$t("general.advancement") },
        { value: "lastCompleted", text: this.$t("general.status") },
        { value: "workOrderType", text: this.$t("general.type") },
        { value: "workOrderGroup", text: this.$t("general.group") },
        { value: "roomName", text: this.$t("general.room") },
        { value: "productionDate", text: this.$t("general.production") },
        { value: "deliveryDate", text: this.$t("general.delivery") },
        { value: "installationDate", text: this.$t("general.installation") },
        { value: "templateName", text: this.$t("general.template") },
        ...this.measureTypes.map((mt) => ({
          value: mt,
          text: this.$t("general." + mt),
        })),
      ];
    },
  },
};
</script>

<template>
  <div class="mb-2 pt-2 pb-2 card card-body boxShadow">
    <div class="workOrderFilters">
      <div
        style="display: flex; align-items: center"
        @click="isOpen = !isOpen"
        class="filterRow"
      >
        <i
          style="font-size: 1.4em; cursor: pointer"
          v-bind:class="{
            'fas fa-angle-down': !isOpen,
            'fas fa-angle-up': isOpen,
          }"
        ></i>
        <span class="ml-2">{{ $t("general.filters") }}</span>
      </div>

      <div v-show="isOpen" class="p-2">
        <div class="row">
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.orderBy')">
              <div class="row no-gutters">
                <div class="col-9 pl-0">
                  <b-form-select
                    :options="orderByOptions"
                    v-model="currentFilters.sort.by"
                  ></b-form-select>
                </div>
                <div class="col-3 pl-2">
                  <b-form-select
                    :options="[
                      { text: 'DESC', value: true },
                      { text: 'ASC', value: false },
                    ]"
                    v-model="currentFilters.sort.desc"
                  ></b-form-select>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.workOrderType')">
              <multiselect
                v-model="currentFilters.workOrderType"
                :options="workOrderTypes"
                :show-labels="false"
                label="name"
                track-by="idPublic"
                :placeholder="''"
              >
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.group')">
              <multiselect
                :multiple="true"
                v-model="currentFilters.workOrderGroups"
                :options="workOrderGroups"
                :options-limit="35"
                @search-change="searchWorkOrderGroups"
                :placeholder="''"
                label="name"
                :loading="isLoadingGroup"
                track-by="idPublic"
                :show-labels="false"
              >
                <template slot="noResult">{{
                  $t("general.noResult")
                }}</template>
                <template slot="noOptions">{{
                  $t("general.searchGroupPlaceholder")
                }}</template>
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <AutoComplete
              id="project-environment"
              class="autocomplete"
              v-model="currentFilters.roomName"
              text="name"
              :list="projectEnvironmentList"
              :label="$t('general.roomName')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.clientName2')">
              <b-form-input
                v-model="currentFilters.clientName"
                type="text"
                :placeholder="''"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <AutoComplete
              id="contractor"
              class="autocomplete"
              v-model="currentFilters.contractor"
              text="name"
              :list="contractorList"
              :label="$t('general.contractor')"
            />
          </div>
          <div class="col-12 col-sm-3">
            <AutoComplete
              id="project-manager"
              class="autocomplete"
              v-model="currentFilters.projectManager"
              text="name"
              :list="projectManagerList"
              :label="$t('general.projectManager')"
            />
          </div>
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.productionDateRange')">
              <date-picker
                v-model="currentFilters.productionDateRange"
                :placeholder="''"
                :formatter="{
                  stringify: (date) => {
                    return date ? $dayjs(date).format('L') : '';
                  },
                }"
                range
              ></date-picker>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.deliveryDateRange')">
              <date-picker
                v-model="currentFilters.deliveryDateRange"
                :placeholder="''"
                :formatter="{
                  stringify: (date) => {
                    return date ? $dayjs(date).format('L') : '';
                  },
                }"
                range
              ></date-picker>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.shippers')">
              <multiselect
                v-model="currentFilters.shippers"
                :options="shippers"
                :show-labels="false"
                label="name"
                track-by="idPublic"
                multiple
                :placeholder="''"
              >
              </multiselect>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.installationDateRange')">
              <date-picker
                :placeholder="''"
                v-model="currentFilters.installationDateRange"
                :formatter="{
                  stringify: (date) => {
                    return date ? $dayjs(date).format('L') : '';
                  },
                }"
                range
              ></date-picker>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3">
            <b-form-group :label="$t('general.installers')">
              <multiselect
                v-model="currentFilters.installers"
                :options="installers"
                :show-labels="false"
                label="name"
                track-by="idPublic"
                multiple
                :placeholder="''"
              >
              </multiselect>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-button
              @click="applyFilters"
              variant="primary"
              class="mr-2"
              size="sm"
            >
              {{ $t("general.search") }}
            </b-button>
            <b-button @click="clearFilters" class="mr-2" size="sm">
              {{ $t("general.clear") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.autocomplete >>> label {
  font-weight: normal;
}
</style>